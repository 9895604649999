<template>
    <div class="app-container-empty">
        <el-row>
            <el-col :style="{width:'calc(50% - 5px)'}">
                <el-card class="positionCard">
                    <div slot="header" class="cardHeader">
                        <el-row>
                            <el-col :span="16">
                                <el-button type="primary" icon="el-icon-upload2" size="small" @click="downEmpTemplate">{{$t('commons.importTemplate')}}</el-button>
                                <el-button type="primary" icon="el-icon-upload" size="small" @click="uploadModel = true">{{$t('commons.import')}}</el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportPosition">{{$t('commons.export')}}</el-button>
                                <el-button type="primary" icon="el-icon-printer" size="small" @click="openPrintModal">{{$t('commons.print')}}</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-select @change="changeProject" :placeholder="$t('iot.position.selectProject')"
                                           v-model="presentProjectId" filterable :style="{width:'100%'}">
                                    <el-option
                                            v-for="item in projectList"
                                            :key="item.projectId"
                                            :value="item.projectId"
                                            :label="item.projectName">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <el-tree
                                v-loading="position.listLoading"
                                :data="position.list"
                                node-key="id"
                                :default-expanded-keys="[-1]"
                                draggable
                                :allow-drop="allowDrop"
                                @node-drag-end="handleDragEnd"
                                show-checkbox
                                :highlight-current="true"
                                :expand-on-click-node="false"
                                ref="tree">
                            <span class="custom-tree-node" slot-scope="{ node, data }"
                                  @mouseenter="mouseoverNode(node)"
                                  @mouseleave="mouseoutNode(node)">
                                <span @click="clickNode(data,node)">{{ node.label }}</span>
                                <template v-if="node.data.show">
                                    <span>
                                        <el-button
                                                type="text"
                                                @click="() => appendNode(node)">
                                            <i class="el-icon-circle-plus-outline"></i>
                                        </el-button>
                                        <template v-if="node.isLeaf">
                                            <el-button
                                                    type="text"
                                                    @click="() => removeNode(node,data)">
                                                <i class="el-icon-delete delete-btn"></i>
                                            </el-button>
                                        </template>
                                    </span>
                                </template>
                            </span>
                        </el-tree>
                    </div>
                </el-card>
            </el-col>
            <el-col :style="{width:'calc(50% - 5px)',marginLeft:'10px'}">
                <el-card class="positionCard">
                    <el-form label-position="top" :model="position.form" ref="positionForm" :rules="formRule">
                        <el-input class="hidden" type="hidden" v-model="position.form.positionId"></el-input>
                        <el-form-item :label="$t('iot.project.name')" prop="projectName">
                            <el-input class="hidden" type="hidden" v-model="position.form.projectId"></el-input>
                            <el-input v-model="position.form.projectName" :readonly="true"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('iot.position.subPosition')" prop="parentId">
                            <el-input type="hidden" class="hidden" v-model="position.form.parentId"></el-input>
                            <el-input v-model="position.form.parentName" :readonly="true"></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('registry.name')" prop="positionName">
                            <el-input v-model="position.form.positionName" maxlength="80" :show-word-limit="true" @input="setCode()" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('iot.position.code')" prop="positionCode">
                            <el-input v-model="position.form.positionCode" maxlength="16" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')" clearable></el-input>
                        </el-form-item>
                        <el-form-item :label="$t('iot.position.label')" prop="positionLabel">
                            <tag-bind :tagValueList="position.form.tagList" tagType="position"/>
                        </el-form-item>
                        <el-form-item :label="$t('iot.position.desc')" prop="description">
                            <el-input type="textarea" :rows="2" v-model="position.form.description" maxlength="160" :show-word-limit="true" :placeholder="$t('commons.pleaseInput')"
                                      autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handlePositionSave()">
                                {{$t('commons.save') }}
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>

        <!--位置打印弹框-->
        <el-dialog :visible.sync="position.printDialogVisible" width="660px">
            <template slot="title">
                <title-icon/>{{ $t('commons.qrCode') }}
            </template>
            <div id="positionLabelPrint">
                <template v-for="item in positionLabelList">
                    <div :key="item.positionId" class="positionQRItem">
                        <vue-qr :text="item.qrText" :size="200"></vue-qr>
                        <p class="positionQRTitle">{{item.descName}}</p>
                    </div>
                </template>
            </div>
            <div slot="footer">
                <el-button @click="cancelPrintPosition">{{$t('commons.cancel') }}</el-button>
                <el-button type="primary" v-print="'#positionLabelPrint'"
                           v-loading.fullscreen.lock="position.createLoading">{{$t('commons.print')}}
                </el-button>
            </div>
        </el-dialog>

        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon/>{{ $t('commons.uploadFile') }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess"
                        @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        createPosition,
        updatePosition,
        deletePosition,
        getPositionById,
        exportPosition,
        downEmployeeTemplate,
        getPositionTree,
    } from '@/api/ruge/iot/position/position'
    import { getProjectList } from '@/api/ruge/iot/project/project'
    import {listToTree} from '@/utils/tree'
    import vueQr from 'vue-qr'
    import {envInfo} from '@/constants/envInfo'
    import Upload from '@/components/Upload'
    import store from '@/store/index'
    import TagBind from '@/components/TagBind'
    import pinyin from 'js-pinyin'

    export default {
        name: "PositionManager",
        components: {
            vueQr,
            Upload,
            TagBind
        },
        data() {
            return {
                presentProjectId: null,
                projectList: [],
                uploadModel: false,
                uploadType: "ExcelImport",
                excelType: "ruge.position",
                rootPath: envInfo.bgApp.consolePath,
                position: {
                    listLoading: false,
                    list: [],
                    form: {
                        positionId: null,
                        positionCode: null,
                        positionName: null,
                        parentId: null,
                        parentName: null,
                        projectId: null,
                        projectName: null,
                        descCode: null,
                        descName: null,
                        description: null,
                        qrText: null,
                        tagList: [],
                    },
                    printDialogVisible: false,
                    createLoading: false
                },
                positionLabelList: [],
                formRule:{
                    positionName : [{
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }],
                    positionCode : [{
                        required: true,
                        trigger: ['blur', 'change'],
                        message: this.$t('validate.required')
                    }]
                }
            }
        },
        created() {
            this.findProjectList().then(()=>{
                this.initLoadPosition();
            });
        },
        methods: {
            setCode(){
                let name = this.position.form.positionName;
                this.position.form.positionCode = pinyin.getCamelChars(name);
            },
            findProjectList() {
                return getProjectList().then(response => {
                    this.projectList = response;
                });
            },
            initLoadPosition(){
                if(this.projectList.length > 0){
                    let obj = this.projectList[0];
                    this.presentProjectId = obj.projectId;
                    this.position.form.projectId = obj.projectId;
                    this.position.form.projectName = obj.projectName;
                    this.position.form.parentId = -1;
                    this.position.form.parentName = this.$t('commons.empty');
                    this.changeProject();
                }
            },
            changeProject() {
                this.clearPositionForm();
                let obj = this.projectList.find((item) => {
                    return item.projectId === this.presentProjectId;//筛选出匹配数据
                });
                this.position.form.projectId = obj.projectId;
                this.position.form.projectName = obj.projectName;
                return this.getPositionTree();
            },
            getPositionTree(){
                return getPositionTree({"projectId": this.presentProjectId}).then(response => {
                    this.position.list = [];
                    let obj = this.projectList.find((item) => {
                        return item.projectId === this.presentProjectId;//筛选出匹配数据
                    });
                    response.push({"positionId": -1, "positionName": obj.projectName})
                    let data = listToTree(response, "positionId", "parentId");
                    this.recursivePosition(data)
                });
            },
            //递归位置列表修改属性及数据结构
            recursivePosition(row) {
                let data = row.map(v => {
                    v.label = v.positionName;
                    v.show = false;
                    v.id = v.positionId;
                    if (v.children != undefined) {
                        this.recursivePosition(v.children);
                    }
                    return v
                })
                this.position.list = data;
            },
            allowDrop(node) {
                if (node.data.parentId == -1) {
                    return false;
                }
                return true;
            },
            handleDragEnd(draggingNode, dropNode, dropType) {
                if (dropType == "before") {
                    draggingNode.data.parentId = dropNode.data.parentId;
                } else if (dropType == "after") {
                    draggingNode.data.parentId = dropNode.data.parentId;
                } else if (dropType == "inner") {
                    draggingNode.data.parentId = dropNode.data.positionId;
                } else if (dropType == "none") {
                    return;
                }
                updatePosition(draggingNode.data).then(() => {
                    // 成功提示
                    this.$message({
                        message: this.$t('message.saveSuccess'),
                        type: 'success'
                    });
                    this.getPositionTree();
                }).catch((error) => {
                    console.log(`保存失败，原因 => ${error}`)
                });
            },
            mouseoverNode(node) {
                node.data.show = true;
            },
            mouseoutNode(node) {
                node.data.show = false;
            },
            removeNode(node, data) {
                this.$confirm(this.$t('message.deleteConfirm'), this.$t('commons.warning'), {
                    confirmButtonText: this.$t('commons.confirm'),
                    cancelButtonText: this.$t('commons.cancel'),
                    type: 'warning'
                }).then(() => {
                    deletePosition({"positionId": data.positionId, "positionName": data.positionName}).then(() => {
                        // 成功提示
                        this.$message({
                            message: this.$t('message.deleteSuccess'),
                            type: 'success'
                        });
                        //异步更新
                        const parent = node.parent;
                        const children = parent.data.children || parent.data;
                        const index = children.findIndex(d => d.id === data.id);
                        children.splice(index, 1);
                        this.clearPositionForm();
                    });
                }).catch(error => {
                    console.log(`未删除，原因 => ${error}`)
                });
            },
            clickNode(data) {
                if (data.positionId != -1) {
                    getPositionById({"positionId": data.positionId}).then((response) => {
                        this.position.form = response;
                        if (response.parentId < 0) {
                            this.position.form.parentName = this.$t('commons.empty');
                        }
                    });
                } else {
                    this.clearPositionForm();
                }
            },
            openPrintModal() {
                let positionList = this.$refs.tree.getCheckedNodes();
                if (positionList.length < 1) {
                    this.$message({
                        type: 'info',
                        message: this.$t('commons.multipleSelectionOne')
                    });
                    return;
                } else {
                    let positionLabelList = [];
                    positionList.forEach(obj => {
                        if (obj.positionId != -1) {
                            obj.qrText = `{"type":"POSITION","positionId":` + obj.positionId + `}`;
                            positionLabelList.push(obj);
                        }
                    })
                    this.positionLabelList = positionLabelList;
                    this.position.printDialogVisible = true;
                }
                this.position.printDialogVisible = true;
            },
            cancelPrintPosition() {
                this.position.printDialogVisible = false;
            },
            appendNode(node) {
                this.clearPositionForm();
                this.position.form.parentId = node.data.positionId;
                this.position.form.parentName = node.data.positionName;
                if (node.data.positionId < 0) {
                    this.position.form.parentName = this.$t('commons.empty');
                }
                let obj = this.projectList.find((item) => {
                    return item.projectId === this.presentProjectId;//筛选出匹配数据
                });
                this.position.form.projectId = obj.projectId;
                this.position.form.projectName = obj.projectName;
            },
            clearPositionForm() {
                this.position.form = {
                    positionId: null,
                    positionCode: null,
                    positionName: null,
                    parentId: -1,
                    parentName: this.$t('commons.empty'),
                    projectId: null,
                    projectName: null,
                    descCode: null,
                    descName: null,
                    description: null,
                    tagList: [],
                };
                let project = this.projectList.find(v=> v.projectId === this.presentProjectId);
                this.position.form.projectId = project.projectId;
                this.position.form.projectName = project.projectName;
                this.$refs.positionForm.resetFields();
            },
            handlePositionSave() {
                this.$refs.positionForm.validate((valid) => {
                    if (valid) {
                        if (this.position.form.projectId == null) {
                            this.$message({
                                type: 'info',
                                message: this.$t('iot.position.prompt')
                            });
                            return;
                        }
                        if (this.position.form.parentId < 0) {
                            this.position.form.parentId = -1;
                        }

                        //去重复
                        this.position.form.tagList = Array.from(new Set(this.position.form.tagList));
                        if (this.position.form.positionId == null) {
                            createPosition(this.position.form).then(() => {
                                // 成功提示
                                this.$message({
                                    message: this.$t('message.saveSuccess'),
                                    type: 'success'
                                });
                                this.getPositionTree();
                                this.clearPositionForm();
                            }).catch((error) => {
                                console.log(`保存失败，原因 => ${error}`)
                            });
                        } else {
                            updatePosition(this.position.form).then(() => {
                                // 成功提示
                                this.$message({
                                    message: this.$t('message.saveSuccess'),
                                    type: 'success'
                                });
                                this.getPositionTree();
                                this.clearPositionForm();
                            }).catch((error) => {
                                console.log(`保存失败，原因 => ${error}`)
                            });
                        }
                    }
                });
            },
            uploadSuccess() {
                this.uploadModel = false
                this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
            },
            uploadFail() {
                this.$message({type: 'error', message: this.$t('message.uploadFial')});
            },
            exportPosition() {
                exportPosition({"projectId": this.presentProjectId}).then((msg) => {
                    this.$message({type: 'success', message: this.$t('message.operationSuccess')});
                    let exportObj = {
                        taskId: msg,
                        taskName: "Position",
                        taskStatus: 0,
                        rootPath: 'consolePath'
                    };
                    //将导出任务丢入导出任务列表中
                    store.dispatch('PushExportNotice', exportObj);
                })
            },
            downEmpTemplate() {
                downEmployeeTemplate();
            },
        }
    }
</script>

<style scoped>

    .positionCard {
        height: calc(100vh - 90px);
    }

    .positionCard >>> .el-card__header {
        padding: 6px 10px;
    }

    .positionCard >>> .el-card__body {
        overflow-y: auto;
        height: calc(100%);
    }

    .positionCard >>> .el-tree {
        margin-bottom: 50px;
    }

    .cardHeader{
        line-height: 34px;
        font-weight: 600;
    }

    .positionQRItem {
        display: inline-grid;
    }

    .positionQRTitle {
        width: 200px;
        padding: 0px 16px;
        text-align: center;
        margin: 0 auto;
    }

</style>
